import request from '@/utils/request'

export function fetchFaqs() {
    return request({
        url: `/api/faq/index`,
        method: 'get',
    })
}


