<template>
  <Fragment>
    <div class="mainh1">
      <div class="wrapper">
        <div class="mainh1_flex">
          <h1>{{ faqContent.title }}</h1>
        </div>
      </div>
    </div>
    <section class="manual_section">
      <div class="wrapper">
        <div class="usermanual">
          <div class="manual_mainimg">
            <img
              :src="
                faqContent.image && faqContent.image.length
                  ? faqContent.image
                  : require(`@/assets/img/user_manual.jpg`)
              "
            />
          </div>
          <div class="user_manual">
            <div v-if="faqContent" v-html="faqContent.description"></div>
            <h2>User Manual</h2>
            <div class="manual_list">
              <div
                class="manual_item"
                v-for="faq in faqContent.faqs"
                :key="faq.id"
              >
                <div class="manual_item_opener">
                  * {{ faq.title
                  }}<span class="manual_right"
                    ><i class="atuicons atuicons-arrowright"></i
                  ></span>
                </div>
                <div class="manual_item_content" v-html="faq.description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
</template>

<script>
import { fetchFaqs } from "@/api/faq";

import $ from "jquery";
import { Fragment } from "vue-fragment";
export default {
  name: "Manual",
  components: { Fragment },
  data() {
    return {
      faqContent: {},
    };
  },
  created() {
    this.fetchFaqs();
  },
  mounted() {
    $(".manual_section").on("click", ".manual_item_opener", function (e) {
      e.preventDefault();
      $(this).toggleClass("opened");
      $(this)
        .closest(".manual_item")
        .find(".manual_item_content")
        .slideToggle(200);
    });
  },
  methods: {
    fetchFaqs() {
      fetchFaqs()
        .then((res) => {
          this.faqContent = res.data.faqContent;
        })
        .catch(function (error) {
          console.log("error while fetching faqs", error);
        });
    },
  },
};
</script>

<style scoped>
@import "~@/assets/css/manual.css";
</style>